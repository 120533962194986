.swiper-wrapper {
    @apply flex transition-transform duration-500 ease-in-out;
  }
  
  .swiper-slide {
    @apply w-full flex-shrink-0;
  }
  
  .swiper-indicators {
    @apply absolute lg:bottom-10 md:bottom-8 bottom-4 left-1/2 transform -translate-x-1/2 flex md:gap-2 gap-1;
  }
  
  .indicator {
    @apply lg:w-4 md:w-3 lg:h-4 md:h-3 w-2 h-2 bg-transparent border-secondaryColor border md:border-[2px]  cursor-pointer mx-2;
  }
  
  .indicator.active {
    @apply bg-secondaryColor;
  }
  
  .swiper-navigation button {
    @apply absolute top-1/2 transform -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 cursor-pointer;
  }
  
  .swiper-navigation .prev {
    @apply left-2;
  }
  
  .swiper-navigation .next {
    @apply right-2;
  }
  